import { Controller } from "stimulus"
import * as WaveformPlaylist from 'waveform-playlist';

export default class extends Controller {
  static targets = [ "status" ]

  initialize() {
    this.playlist = WaveformPlaylist.init({
      samplesPerPixel: 5000,
      waveHeight: 100,
      container: document.getElementById("playlist"),
      timescale: true,
      isAutomaticScroll: true,
      state: 'cursor',
      exclSolo: false,
      mono: true,
      colors: {
        waveOutlineColor: "#E0EFF1",
        // waveOutlineColor: "#005BBB",
      },
      controls: {
        show: true, //whether or not to include the track controls
        width: 200 //width of controls in pixels
      },
      zoomLevels: [500, 1000, 3000, 5000, 10000, 20000],

      annotationList: {
        annotations: annotations,
        editable: true,
        controls: [
          {
            class: 'fas.fa-minus',
            title: 'Reduce annotation end by 1s',
            action: (annotation, i, annotations, opts) => {
              var next;
              var delta = 1;
              annotation.end -= delta;

              if (opts.linkEndpoints) {
                next = annotations[i + 1];
                next && (next.start -= delta);
              }
            }
          },
          {
            class: 'fas.fa-plus',
            title: 'Increase annotation end by 1s',
            action: (annotation, i, annotations, opts) => {
              var next;
              var delta = 1;
              annotation.end += delta;

              if (opts.linkEndpoints) {
                next = annotations[i + 1];
                next && (next.start += delta);
              }
            }
          },
          {
            class: 'fas.fa-cut',
            title: 'Split annotation in half',
            action: (annotation, i, annotations) => {
              const halfDuration = (annotation.end - annotation.start) / 2;

              annotations.splice(i + 1, 0, {
                id: 'test',
                start: annotation.end - halfDuration,
                end: annotation.end,
                lines: ['----'],
                lang: 'en',
              });

              annotation.end = annotation.start + halfDuration;
            }
          },
          {
            class: 'fas.fa-trash',
            title: 'Delete annotation',
            action: (annotation, i, annotations) => {
              annotations.splice(i, 1);
            }
          }
        ],
        isContinuousPlay: false,
        linkEndpoints: true
      }

    });
    this.playlist.ee.on("mute", function(track) {
      console.log("Track: " + track.name + " muteFlag is " + track.muteClass);
    });
    this.playlist.ee.on("volumechange", function(volume, track) {
      console.log("Track: " + track.name + " volume changed to " + volume);
    });
  }

  connect() {
    this.load_tracks();
  }

  disconnect() {
    console.log("Disconnect event")
    this.playlist.ee.emit("clear")
  }

  setExclSolo(e) {
    console.log("Setting Exclusive Solo flag");
    this.playlist.setExclSolo(e.target.checked);
  }

  // initYT() {
  //   var vidId = null
  //   vidId = this.statusTarget.dataset.videoid
  //   if (vidId != null){
  //    this.ytplayer = new YT.Player('ytplayer', {
  //       height: 315, width: 560,
  //       videoId: vidId,
  //       playerVars: { autoplay: 0, playsinline: 1, origin: 'https://pruthak.com/' },
  //     });
  //   }
  // }

  load_tracks() {
    this.statusTarget.textContent = "Loading separated tracks...."
    var tgt = this.statusTarget
    this.playlist.load(playlistinfo).then(function() {
      tgt.textContent = "";
      document.querySelector('#spinnerdiv').classList.toggle('d-none');
      document.querySelector('.playlist-toolbar').classList.toggle('d-none');
      // console.log("Pruthak: finished loading tracks");
    });
  }

  pause_tracks() {
    this.playlist.ee.emit("pause");
  }
  play_tracks() {
    this.playlist.ee.emit("play");
    gtag('event', 'PLAY', {
                  'event_category': window.location.href,
                  'event_label':'Play Tracks',
                });
  }
  stop_tracks() {
    this.playlist.ee.emit("stop");
    gtag('event', 'STOP', {
                  'event_category': window.location.href,
                  'event_label':'Stop Tracks',
                });
  }
  rew_tracks() {
    this.playlist.ee.emit("rewind");
  }
  fwd_tracks() {
    this.playlist.ee.emit("fastforward");
  }
  zoom_out() {
    this.playlist.ee.emit("zoomout")
  }
  zoom_in() {
    this.playlist.ee.emit("zoomin")
  }
  download_json() {
    this.playlist.ee.emit("annotationsrequest")
  }
  mastervolumechange(e) {
    this.playlist.ee.emit("mastervolumechange", e.target.value);
  }
  autoscrollchange(e) {
    this.playlist.ee.emit("automaticscroll", e.target.checked);
  }
}
