import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tracktype"]

  update_type(event) {
    console.log("Hello World!");
    var opt = $(event.target).find(":selected").text();
    if (opt == 'YouTube track link'){
      $('#youtubeul').removeClass('d-none');
      $('#fileul').addClass('d-none');
    }
    if (opt == 'Upload MP3 audio'){
      $('#youtubeul').addClass('d-none');
      $('#fileul').removeClass('d-none');
    }
  }

}
